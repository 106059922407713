@font-face {
  font-family: 'NexonLv2Gothic';
  src: url('../../fonts/NEXON\ Lv2\ Gothic.eot');
  src: url('../../fonts/NEXON\ Lv2\ Gothic.eot') format('embedded-opentype'), url('../../fonts/NEXON\ Lv2\ Gothic.woff') format('woff'), url('../../fonts/NEXON\ Lv2\ Gothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NexonLv2Gothic';
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.eot');
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.eot') format('embedded-opentype'), url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.woff') format('woff'), url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NexonLv2Gothic';
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Light.eot');
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Light.eot') format('embedded-opentype'), url('../../fonts/NEXON\ Lv2\ Gothic\ Light.woff') format('woff'), url('../../fonts/NEXON\ Lv2\ Gothic\ Light.ttf') format('truetype');
  font-weight: 100 400;
  font-style: normal;
}

.msp {
  font-family: 'NexonLv2Gothic';
  // background-color: #fef7f8;

  .link {
    color: $primary-color;
    cursor: pointer;
  }

  .title {
    font-weight: bold;
    font-size: 2.5em;
    color: #2355a4;
  }

  .name {
    font-weight: 600;
    font-size: 2.5em;
    color: #231815;
  }

  .name-sub {
    font-weight: 500;
    font-size: 1.5em;
    color: #9fa0a0;
  }

  thead {
    font-weight: 500;
    font-size: 1.2em;
    color: #231815;
  }

  tbody {
    font-weight: 300;
    font-size: 1.1em;
    color: #231815;

    .bold {
      font-weight: bold;
    }

    .small-height {
      padding-top: 0em;
      padding-bottom: 0em;
    }
  }

  .bmi {
    font-weight: 400;
    color: #ffffff;
    font-size: 1.2em;

    .thin {
      background-color: #2355a4;
    }

    .normal {
      background-color: #624e9d;
    }

    .fat {
      background-color: #e74692;
    }

  }

  .measure {
    font-weight: 400;
    color: #ffffff;
    font-size: 1.2em;

    .first {
      background-color: #2355a4;
    }

    .second {
      background-color: #624e9d;
    }

    .third {
      background-color: #e74692;
    }
  }

  .section-title {
    background-color: #2355a4;
    color: #ffffff;
    font-weight: 900;
    font-size: 1.6em;
    text-align: center;
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
  }

  .section-line {
    background-color: #2355a4;
    height: 2px;
    width: 100%;
  }

  .graph-comment {
    font-size: 0.8em;
  }

  @media print {

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-print-3 {
      float: left;
    }

    .col-print-3 {
      width: 25%;
    }

    .col-sm-11 {
      width: 91.66666667%;
    }

    .col-sm-10 {
      width: 83.33333333%;
    }

    .col-sm-9 {
      width: 75%;
    }

    .col-sm-8 {
      width: 66.66666667%;
    }

    .col-sm-7 {
      width: 58.33333333%;
    }

    .col-sm-6 {
      width: 50%;
    }

    .col-sm-5 {
      width: 41.66666667%;
    }

    .col-sm-4 {
      width: 33.33333333%;
    }

    .col-sm-3 {
      width: 25%;
    }

    .col-sm-2 {
      width: 16.66666667%;
    }

    .col-sm-1 {
      width: 8.33333333%;
    }

    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
      float: left;
    }

    .col-xs-12 {
      width: 25%;
    }

    .col-xs-11 {
      width: 91.66666667%;
    }

    .col-xs-10 {
      width: 83.33333333%;
    }

    .col-xs-9 {
      width: 75%;
    }

    .col-xs-8 {
      width: 66.66666667%;
    }

    .col-xs-7 {
      width: 58.33333333%;
    }

    .col-xs-6 {
      width: 50%;
    }

    .col-xs-5 {
      width: 41.66666667%;
    }

    .col-xs-4 {
      width: 33.33333333%;
    }

    .col-xs-3 {
      width: 25%;
    }

    .col-xs-2 {
      width: 16.66666667%;
    }

    .col-xs-1 {
      width: 8.33333333%;
    }
  }
}