@font-face {
  font-family: 'NexonLv2Gothic';
  src: url('../../fonts/NEXON\ Lv2\ Gothic.eot');
  src: url('../../fonts/NEXON\ Lv2\ Gothic.eot') format('embedded-opentype'), url('../../fonts/NEXON\ Lv2\ Gothic.woff') format('woff'), url('../../fonts/NEXON\ Lv2\ Gothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NexonLv2Gothic';
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.eot');
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.eot') format('embedded-opentype'), url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.woff') format('woff'), url('../../fonts/NEXON\ Lv2\ Gothic\ Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'NexonLv2Gothic';
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Light.eot');
  src: url('../../fonts/NEXON\ Lv2\ Gothic\ Light.eot') format('embedded-opentype'), url('../../fonts/NEXON\ Lv2\ Gothic\ Light.woff') format('woff'), url('../../fonts/NEXON\ Lv2\ Gothic\ Light.ttf') format('truetype');
  font-weight: 100 400;
  font-style: normal;
}

.msp-subgroup {
  font-family: 'NexonLv2Gothic';
  // background-color: #fef7f8;

  .name {
    font-weight: bold;
    font-size: 1.1em;
  }

  .number {
    font-weight: bold;
    font-size: 1.0em;
    color: #8f8b89a9;
    margin-right: 0.5em;
  }

  .list-item div:hover {
    background-color: 'yellow';
  }
}